.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.2px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #bee9ee;
}

.App .primaryBg {
  background-color: #bee9ee;
}

.App .highlightTextColor {
  color: #0093d5;
}

.App .highlightBg {
  background-color: #0093d5;
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #bee9ee;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}
.mui-btn--accent {
    background-color: #0093d5;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #069fda;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 19.0px;
  font-weight: 400;
}
.App .headlineFont * {
  font-size: 19.0px;
}
.App .navBar {
  background-color: #bee9ee;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

/* This component has a fixed-size layout */
  .BookJob {
    min-height: 252px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 337px */
    height: 100%;
  }
  .BookJob > .background > .containerMinHeight {
    min-height: 252px;
  }


  .BookJob > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 428px;
  }


  .BookJob > .layoutFlow > .elText {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .BookJob > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  


  .BookJob > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elTextCopy2 > * { 
  width: 238px;
  }
  


  .BookJob > .layoutFlow > .elTextCopy8 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elTextCopy8 > * { 
  width: 238px;
  }
  


  .BookJob > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elTextCopy3 > * { 
  width: 238px;
  }
  


  .BookJob > .layoutFlow > .elTextCopy4 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elTextCopy4 > * { 
  width: 238px;
  }
  


  .BookJob > .layoutFlow > .elTextCopy5 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elTextCopy5 > * { 
  width: 238px;
  }
  


  .BookJob > .layoutFlow > .elTextCopy6 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 32px;
  }
  .BookJob > .layoutFlow > .elTextCopy6 > * { 
  width: 238px;
  }
  

.BookJob .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.BookJob .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .BookJob > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 24px;
    width: 145px;
    margin-left: 90px;
  }
  .BookJob > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  


/* Narrowest size for this element's responsive layout */
  .JobDetailsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .JobDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .JobDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .JobDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .JobDetailsScreen > .background > .elTextBlock {
    position: absolute;
    left: calc(50.0% + 0px - 141px);
    top: 472px;
    width: 282px;
    height: 41px;
  }
@media (min-width: 568px) {
  .JobDetailsScreen > .background > .elTextBlock {
    left: calc(50.0% + 0px - 141px);
    top: 471px;
    width: 282px;
    height: 41px;
  }
}
@media (min-width: 768px) {
  .JobDetailsScreen > .background > .elTextBlock {
    left: calc(50.0% + 0px - 140px);
    top: 469px;
    width: 280px;
    height: 41px;
  }
}
@media (min-width: 1024px) {
  .JobDetailsScreen > .background > .elTextBlock {
    left: calc(50.0% + 0px - 140px);
    top: 468px;
    width: 280px;
    height: 41px;
  }
}

.JobDetailsScreen .elBookJob {
  overflow: hidden;
}

.JobDetailsScreen .elBookJob.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .JobDetailsScreen > .layoutFlow > .elBookJob {
    position: relative;
    margin-top: 83px;
    width: 337px;
    margin-left: calc(50.0% - 168px);
  }
  .JobDetailsScreen > .layoutFlow > .elBookJob > * { 
  width: 337px;
  }
  
  .JobDetailsScreen > .layoutFlow > .elBookJob > .background > .containerMinHeight {
    min-height: 252px;
  }

@media (min-width: 568px) {
  .JobDetailsScreen > .layoutFlow > .elBookJob {
    position: relative;
    margin-top: 83px;
    width: 337px;
    margin-left: calc(50.0% - 168px);
  }
  .JobDetailsScreen > .layoutFlow > .elBookJob > * { 
  width: 337px;
  }
  
  .JobDetailsScreen > .layoutFlow > .elBookJob > .background > .containerMinHeight {
    min-height: 252px;
  }

}
@media (min-width: 768px) {
  .JobDetailsScreen > .layoutFlow > .elBookJob {
    position: relative;
    margin-top: 82px;
    width: 335px;
    margin-left: calc(50.0% - 168px);
  }
  .JobDetailsScreen > .layoutFlow > .elBookJob > * { 
  width: 335px;
  }
  
  .JobDetailsScreen > .layoutFlow > .elBookJob > .background > .containerMinHeight {
    min-height: 250px;
  }

}
@media (min-width: 1024px) {
  .JobDetailsScreen > .layoutFlow > .elBookJob {
    position: relative;
    margin-top: 82px;
    width: 335px;
    margin-left: calc(50.0% - 168px);
  }
  .JobDetailsScreen > .layoutFlow > .elBookJob > * { 
  width: 335px;
  }
  
  .JobDetailsScreen > .layoutFlow > .elBookJob > .background > .containerMinHeight {
    min-height: 250px;
  }

}

.circularProgressIndicator {
    position: relative;
    width: 50px;
    height: 50px;
    display: none;
}
.circularProgressIndicator-active {
    animation: rotate 2s linear infinite;
    display: inline;
}
.circularProgress-animatedPath {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: circularProgress-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate{
    100%{
    transform: rotate(360deg);
    }
}
@keyframes circularProgress-dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



/* Narrowest size for this element's responsive layout */
  .JobDetailsScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 160px);
    right: 0px;
    top: 118px;
    width: 50.0%;
    height: 48px;
  }
@media (min-width: 568px) {
  .JobDetailsScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 284px);
    right: 0px;
    top: 118px;
    width: 50.0%;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .JobDetailsScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 384px);
    right: 0px;
    top: 117px;
    width: 50.0%;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .JobDetailsScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 512px);
    right: 0px;
    top: 117px;
    width: 50.0%;
    height: 47px;
  }
}

/* This component has a fixed-size layout */
  .JobItem {
    min-height: 354px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 334px */
    height: 100%;
  }
  .JobItem > .background > .containerMinHeight {
    min-height: 354px;
  }


  .JobItem > .background > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 428px;
  }


  .JobItem > .layoutFlow > .elPixel012 {
    position: relative;
    margin-top: -0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .JobItem > .layoutFlow > .elPixel012 > * { 
    width: 100%;
  height: 142px;
  }
  


  .JobItem > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 8px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy3 > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elTextCopy4 {
    position: relative;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy4 > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elTextCopy5 {
    position: relative;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy5 > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elTextCopy6 {
    position: relative;
    margin-top: -2px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy6 > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elTextCopy7 {
    position: relative;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy7 > * { 
    width: 100%;
  }
  


  .JobItem > .layoutFlow > .elTextCopy8 {
    position: relative;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItem > .layoutFlow > .elTextCopy8 > * { 
    width: 100%;
  }
  

.JobItem .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.JobItem .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .JobItem > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: -0px;
    width: 145px;
    margin-left: 180px;
  }
  .JobItem > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  

/* This component has a fixed-size layout */
  .InvoiceItem {
    min-height: 150px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 334px */
    height: 100%;
  }
  .InvoiceItem > .background > .containerMinHeight {
    min-height: 150px;
  }


  .InvoiceItem > .background > .elBackground {
    position: absolute;
    top: 0px;
    width: 334px;
    height: 428px;
  }


  .InvoiceItem > .layoutFlow > .elText {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 64px;
  }
  .InvoiceItem > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .InvoiceItem > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 64px;
  }
  .InvoiceItem > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  


  .InvoiceItem > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 64px;
  }
  .InvoiceItem > .layoutFlow > .elTextCopy2 > * { 
  width: 238px;
  }
  


  .InvoiceItem > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 64px;
  }
  .InvoiceItem > .layoutFlow > .elTextCopy3 > * { 
  width: 238px;
  }
  


  .InvoiceItem > .layoutFlow > .elTextCopy4 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 64px;
  }
  .InvoiceItem > .layoutFlow > .elTextCopy4 > * { 
  width: 238px;
  }
  


  .InvoiceItem > .layoutFlow > .elTextCopy5 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 64px;
  }
  .InvoiceItem > .layoutFlow > .elTextCopy5 > * { 
  width: 238px;
  }
  

.InvoiceItem .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.InvoiceItem .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .InvoiceItem > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 0px;
    width: 145px;
    margin-left: 132px;
  }
  .InvoiceItem > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  


/* Narrowest size for this element's responsive layout */
  .JobsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .JobsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .JobsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .JobsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.JobsScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .JobsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 109px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .JobsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .JobsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 109px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .JobsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .JobsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 108px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .JobsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .JobsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 108px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .JobsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .JobsScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 160px);
    right: 0px;
    top: 138px;
    width: 50.0%;
    height: 48px;
  }
@media (min-width: 568px) {
  .JobsScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 284px);
    right: 0px;
    top: 138px;
    width: 50.0%;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .JobsScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 384px);
    right: 0px;
    top: 137px;
    width: 50.0%;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .JobsScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 512px);
    right: 0px;
    top: 137px;
    width: 50.0%;
    height: 47px;
  }
}

/* This component has a fixed-size layout */
  .ProfilePic {
    min-height: 161px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }
  .ProfilePic > .background > .containerMinHeight {
    min-height: 161px;
  }


  .ProfilePic > .layoutFlow > .elText {
    position: relative;
    margin-top: 0px;
    width: 155px;
    margin-left: 0px;
  }
  .ProfilePic > .layoutFlow > .elText > * { 
  width: 155px;
  }
  


  .ProfilePic > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: 8px;
    width: 76px;
    margin-left: calc(50.0% - 38px);
  }
  .ProfilePic > .layoutFlow > .flowRow > .elImage > * { 
  width: 76px;
  height: 114px;
  }
  
  .flowRow_ProfilePic_elImage_179424 { 
  }
  


/* Narrowest size for this element's responsive layout */
  .ScheduledScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ScheduledScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ScheduledScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ScheduledScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.ScheduledScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .ScheduledScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 109px;
    width: 337px;
    margin-left: -8px;
  }
  .ScheduledScreen > .layoutFlow > .elList > * { 
  width: 337px;
  }
  
@media (min-width: 568px) {
  .ScheduledScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 109px;
    width: 337px;
    margin-left: -8px;
  }
  .ScheduledScreen > .layoutFlow > .elList > * { 
  width: 337px;
  }
  
}
@media (min-width: 768px) {
  .ScheduledScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 108px;
    width: 335px;
    margin-left: -8px;
  }
  .ScheduledScreen > .layoutFlow > .elList > * { 
  width: 335px;
  }
  
}
@media (min-width: 1024px) {
  .ScheduledScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 108px;
    width: 335px;
    margin-left: -8px;
  }
  .ScheduledScreen > .layoutFlow > .elList > * { 
  width: 335px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ScheduledScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 138px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .ScheduledScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 138px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .ScheduledScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 137px;
    width: 47px;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .ScheduledScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 137px;
    width: 47px;
    height: 47px;
  }
}


/* Narrowest size for this element's responsive layout */
  .InvoicesScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .InvoicesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .InvoicesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .InvoicesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.InvoicesScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .InvoicesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 109px;
    width: 320px;
    margin-left: 1px;
  }
  .InvoicesScreen > .layoutFlow > .elList > * { 
  width: 320px;
  }
  
@media (min-width: 568px) {
  .InvoicesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 109px;
    width: 320px;
    margin-left: 1px;
  }
  .InvoicesScreen > .layoutFlow > .elList > * { 
  width: 320px;
  }
  
}
@media (min-width: 768px) {
  .InvoicesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 108px;
    width: 318px;
    margin-left: 1px;
  }
  .InvoicesScreen > .layoutFlow > .elList > * { 
  width: 318px;
  }
  
}
@media (min-width: 1024px) {
  .InvoicesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 108px;
    width: 318px;
    margin-left: 1px;
  }
  .InvoicesScreen > .layoutFlow > .elList > * { 
  width: 318px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .InvoicesScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 138px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .InvoicesScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 138px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .InvoicesScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 137px;
    width: 47px;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .InvoicesScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 137px;
    width: 47px;
    height: 47px;
  }
}

/* This component has a fixed-size layout */
  .MenuItems {
    min-height: 193px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 291px */
    height: 100%;
  }
  .MenuItems > .background > .containerMinHeight {
    min-height: 193px;
  }


  .MenuItems > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .MenuItems > .background > .elText2 {
    position: absolute;
    left: calc(50.0% + 0px - 119px);
    top: 108px;
    width: 238px;
    height: 22px;
  }


  .MenuItems > .background > .elText {
    position: absolute;
    left: calc(50.0% + 0px - 119px);
    top: 46px;
    width: 238px;
    height: 22px;
  }


  .MenuItems > .background > .elTextCopy {
    position: absolute;
    left: calc(50.0% + 0px - 119px);
    top: 168px;
    width: 238px;
    height: 22px;
  }

.MenuItems .elIconButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.MenuItems .elIconButton button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .MenuItems > .layoutFlow > .elIconButton {
    position: relative;
    margin-top: 28px;
    width: 41px;
    margin-left: 16px;
  }
  .MenuItems > .layoutFlow > .elIconButton > * { 
  width: 41px;
  height: 36px;
  }
  

.MenuItems .elIconButtonCopy button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.MenuItems .elIconButtonCopy button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .MenuItems > .layoutFlow > .elIconButtonCopy {
    position: relative;
    margin-top: 28px;
    width: 41px;
    margin-left: 16px;
  }
  .MenuItems > .layoutFlow > .elIconButtonCopy > * { 
  width: 41px;
  height: 36px;
  }
  

.MenuItems .elIconButtonCopy2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.MenuItems .elIconButtonCopy2 button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .MenuItems > .layoutFlow > .elIconButtonCopy2 {
    position: relative;
    margin-top: 28px;
    width: 41px;
    margin-left: 16px;
  }
  .MenuItems > .layoutFlow > .elIconButtonCopy2 > * { 
  width: 41px;
  height: 36px;
  }
  

/* This component has a fixed-size layout */
  .AccountDetails {
    min-height: 358px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 315px */
    height: 100%;
  }
  .AccountDetails > .background > .containerMinHeight {
    min-height: 358px;
  }

.AccountDetails .elProfilePic {
  overflow: hidden;
}

.AccountDetails .elProfilePic.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .AccountDetails > .layoutFlow > .elProfilePic {
    position: relative;
    margin-top: 18px;
    width: 182px;
    margin-left: calc(50.0% - 91px);
  }
  .AccountDetails > .layoutFlow > .elProfilePic > * { 
  width: 182px;
  }
  


  .AccountDetails > .layoutFlow > .elText {
    position: relative;
    margin-top: 41px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountDetails > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .AccountDetails > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 41px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountDetails > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  

.AccountDetails .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.AccountDetails .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .AccountDetails > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 22px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountDetails > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  


/* Narrowest size for this element's responsive layout */
  .MenuScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MenuScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MenuScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MenuScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.MenuScreen .elMenu {
  overflow: hidden;
}

.MenuScreen .elMenu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MenuScreen > .layoutFlow > .elMenu {
    position: relative;
    margin-top: 100px;
    width: 291px;
    margin-left: 15px;
  }
  .MenuScreen > .layoutFlow > .elMenu > * { 
  width: 291px;
  }
  
  .MenuScreen > .layoutFlow > .elMenu > .background > .containerMinHeight {
    min-height: 193px;
  }

@media (min-width: 568px) {
  .MenuScreen > .layoutFlow > .elMenu {
    position: relative;
    margin-top: 100px;
    width: 291px;
    margin-left: 15px;
  }
  .MenuScreen > .layoutFlow > .elMenu > * { 
  width: 291px;
  }
  
  .MenuScreen > .layoutFlow > .elMenu > .background > .containerMinHeight {
    min-height: 193px;
  }

}
@media (min-width: 768px) {
  .MenuScreen > .layoutFlow > .elMenu {
    position: relative;
    margin-top: 100px;
    width: 289px;
    margin-left: 14px;
  }
  .MenuScreen > .layoutFlow > .elMenu > * { 
  width: 289px;
  }
  
  .MenuScreen > .layoutFlow > .elMenu > .background > .containerMinHeight {
    min-height: 192px;
  }

}
@media (min-width: 1024px) {
  .MenuScreen > .layoutFlow > .elMenu {
    position: relative;
    margin-top: 100px;
    width: 289px;
    margin-left: 14px;
  }
  .MenuScreen > .layoutFlow > .elMenu > * { 
  width: 289px;
  }
  
  .MenuScreen > .layoutFlow > .elMenu > .background > .containerMinHeight {
    min-height: 192px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AccountScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AccountScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AccountScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AccountScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountScreen > .layoutFlow > .flowRow > .elProgress {
    position: absolute;
    margin-top: 138px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .AccountScreen > .layoutFlow > .flowRow > .elProgress > * { 
  width: 48px;
  }
  
  .flowRow_AccountScreen_elProgress_345413 { 
  }
  
@media (min-width: 568px) {
  .AccountScreen > .layoutFlow > .flowRow > .elProgress {
    position: absolute;
    margin-top: 138px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .AccountScreen > .layoutFlow > .flowRow > .elProgress > * { 
  width: 48px;
  }
  
  .flowRow_AccountScreen_elProgress_345413 { 
  }
  
}
@media (min-width: 768px) {
  .AccountScreen > .layoutFlow > .flowRow > .elProgress {
    position: absolute;
    margin-top: 138px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .AccountScreen > .layoutFlow > .flowRow > .elProgress > * { 
  width: 47px;
  }
  
  .flowRow_AccountScreen_elProgress_345413 { 
  }
  
}
@media (min-width: 1024px) {
  .AccountScreen > .layoutFlow > .flowRow > .elProgress {
    position: absolute;
    margin-top: 138px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .AccountScreen > .layoutFlow > .flowRow > .elProgress > * { 
  width: 47px;
  }
  
  .flowRow_AccountScreen_elProgress_345413 { 
  }
  
}

.AccountScreen .elAccountDetails {
  overflow: hidden;
}

.AccountScreen .elAccountDetails.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AccountScreen > .layoutFlow > .elAccountDetails {
    position: relative;
    margin-top: 108px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AccountScreen > .layoutFlow > .elAccountDetails > * { 
    width: 100%;
  }
  
  .AccountScreen > .layoutFlow > .elAccountDetails > .background > .containerMinHeight {
    min-height: 358px;
  }

@media (min-width: 568px) {
  .AccountScreen > .layoutFlow > .elAccountDetails {
    position: relative;
    margin-top: 108px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AccountScreen > .layoutFlow > .elAccountDetails > * { 
    width: 100%;
  }
  
  .AccountScreen > .layoutFlow > .elAccountDetails > .background > .containerMinHeight {
    min-height: 358px;
  }

}
@media (min-width: 768px) {
  .AccountScreen > .layoutFlow > .elAccountDetails {
    position: relative;
    margin-top: 108px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AccountScreen > .layoutFlow > .elAccountDetails > * { 
    width: 100%;
  }
  
  .AccountScreen > .layoutFlow > .elAccountDetails > .background > .containerMinHeight {
    min-height: 356px;
  }

}
@media (min-width: 1024px) {
  .AccountScreen > .layoutFlow > .elAccountDetails {
    position: relative;
    margin-top: 108px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AccountScreen > .layoutFlow > .elAccountDetails > * { 
    width: 100%;
  }
  
  .AccountScreen > .layoutFlow > .elAccountDetails > .background > .containerMinHeight {
    min-height: 356px;
  }

}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Screen23 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Screen23 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 238px;
    margin-left: 46px;
  }
  .Screen23 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .Screen23 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 238px;
    margin-left: 46px;
  }
  .Screen23 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .Screen23 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 236px;
    margin-left: 46px;
  }
  .Screen23 > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .Screen23 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 236px;
    margin-left: 46px;
  }
  .Screen23 > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .screenFgContainer > .foreground > .elDashboard {
    position: absolute;
    left: 59px;
    top: 133px;
    width: 208px;
    height: 370px;
  }
@media (min-width: 568px) {
  .Screen23 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 133px;
    width: 208px;
    height: 370px;
  }
}
@media (min-width: 768px) {
  .Screen23 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 132px;
    width: 207px;
    height: 367px;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 132px;
    width: 207px;
    height: 367px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 8px;
    top: 467px;
    width: 302px;
    height: 28px;
  }
@media (min-width: 568px) {
  .Screen23 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 467px;
    width: 301px;
    height: 28px;
  }
}
@media (min-width: 768px) {
  .Screen23 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 464px;
    width: 300px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 464px;
    width: 300px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    position: absolute;
    left: 18px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .Screen23 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 18px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .Screen23 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 17px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 17px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .screenFgContainer > .foreground > .elEllipse {
    position: absolute;
    left: calc(50.0% + 27px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy {
    position: absolute;
    left: calc(50.0% + 0px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    position: absolute;
    left: calc(50.0% + -30px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen23 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    position: absolute;
    left: 263px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .Screen23 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 263px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .Screen23 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 261px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .Screen23 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 261px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}

/* This component has a fixed-size layout */
  .MenuButton {
    min-height: 58px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 45px */
    height: 100%;
  }
  .MenuButton > .background > .containerMinHeight {
    min-height: 58px;
  }


  .MenuButton > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.MenuButton .elIconButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.MenuButton .elIconButton button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4250);
  outline: none;
}



  .MenuButton > .layoutFlow > .flowRow > .elIconButton {
    position: absolute;
    margin-top: 8px;
    width: 41px;
    margin-left: 0px;
  }
  .MenuButton > .layoutFlow > .flowRow > .elIconButton > * { 
  width: 41px;
  height: 36px;
  }
  
  .flowRow_MenuButton_elIconButton_275761 { 
  }
  

/* This component has a fixed-size layout */
  .AccountButton {
    min-height: 58px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 45px */
    height: 100%;
  }
  .AccountButton > .background > .containerMinHeight {
    min-height: 58px;
  }


  .AccountButton > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.AccountButton .elIconButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.AccountButton .elIconButton button:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2125);
  outline: none;
}



  .AccountButton > .layoutFlow > .flowRow > .elIconButton {
    position: absolute;
    margin-top: 8px;
    width: 41px;
    margin-left: 0px;
  }
  .AccountButton > .layoutFlow > .flowRow > .elIconButton > * { 
  width: 41px;
  height: 36px;
  }
  
  .flowRow_AccountButton_elIconButton_507638 { 
  }
  

/* This component has a fixed-size layout */
  .ScheduledItem {
    min-height: 150px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 337px */
    height: 100%;
  }
  .ScheduledItem > .background > .containerMinHeight {
    min-height: 150px;
  }


  .ScheduledItem > .background > .elBackground {
    position: absolute;
    top: 0px;
    width: 336px;
    height: 428px;
  }


  .ScheduledItem > .layoutFlow > .elText {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 12px;
  }
  .ScheduledItem > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .ScheduledItem > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 12px;
  }
  .ScheduledItem > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  


  .ScheduledItem > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 12px;
  }
  .ScheduledItem > .layoutFlow > .elTextCopy2 > * { 
  width: 238px;
  }
  


  .ScheduledItem > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 12px;
  }
  .ScheduledItem > .layoutFlow > .elTextCopy3 > * { 
  width: 238px;
  }
  


  .ScheduledItem > .layoutFlow > .elTextCopy4 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 12px;
  }
  .ScheduledItem > .layoutFlow > .elTextCopy4 > * { 
  width: 238px;
  }
  


  .ScheduledItem > .layoutFlow > .elTextCopy5 {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 12px;
  }
  .ScheduledItem > .layoutFlow > .elTextCopy5 > * { 
  width: 238px;
  }
  

.ScheduledItem .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ScheduledItem .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .ScheduledItem > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 0px;
    width: 145px;
    margin-left: 178px;
  }
  .ScheduledItem > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  


/* Narrowest size for this element's responsive layout */
  .Screen22 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Screen22 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Screen22 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 238px;
    margin-left: 46px;
  }
  .Screen22 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .Screen22 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 238px;
    margin-left: 46px;
  }
  .Screen22 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .Screen22 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 236px;
    margin-left: 46px;
  }
  .Screen22 > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .Screen22 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 236px;
    margin-left: 46px;
  }
  .Screen22 > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .screenFgContainer > .foreground > .elDashboard {
    position: absolute;
    left: 59px;
    top: 133px;
    width: 208px;
    height: 370px;
  }
@media (min-width: 568px) {
  .Screen22 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 133px;
    width: 208px;
    height: 370px;
  }
}
@media (min-width: 768px) {
  .Screen22 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 132px;
    width: 207px;
    height: 367px;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 132px;
    width: 207px;
    height: 367px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 8px;
    top: 467px;
    width: 302px;
    height: 28px;
  }
@media (min-width: 568px) {
  .Screen22 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 467px;
    width: 301px;
    height: 28px;
  }
}
@media (min-width: 768px) {
  .Screen22 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 464px;
    width: 300px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 464px;
    width: 300px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    position: absolute;
    left: 18px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .Screen22 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 18px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .Screen22 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 17px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 17px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .screenFgContainer > .foreground > .elEllipse {
    position: absolute;
    left: calc(50.0% + 27px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy {
    position: absolute;
    left: calc(50.0% + 0px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    position: absolute;
    left: calc(50.0% + -30px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen22 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    position: absolute;
    left: 263px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .Screen22 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 263px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .Screen22 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 261px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .Screen22 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 261px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DashboardScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DashboardScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DashboardScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DashboardScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash {
    position: relative;
    margin-top: 64px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash {
    position: relative;
    margin-top: 64px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash {
    position: relative;
    margin-top: 64px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash {
    position: relative;
    margin-top: 64px;
    margin-left: 50px;
    margin-right: 0px;
    width: calc(100.0% - 50px);
  }
  .DashboardScreen > .layoutFlow > .elTylerfranta603090unsplash > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DashboardScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DashboardScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DashboardScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DashboardScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DashboardScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 12px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DashboardScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 12px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DashboardScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 12px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DashboardScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 12px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: calc(85.0% - 0px);
  }
  .DashboardScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}

.DashboardScreen .elScheduledItem {
  overflow: hidden;
}

.DashboardScreen .elScheduledItem.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DashboardScreen > .layoutFlow > .elScheduledItem {
    position: relative;
    margin-top: 36px;
    width: 334px;
    margin-left: 0px;
  }
  .DashboardScreen > .layoutFlow > .elScheduledItem > * { 
  width: 334px;
  }
  
  .DashboardScreen > .layoutFlow > .elScheduledItem > .background > .containerMinHeight {
    min-height: 150px;
  }

@media (min-width: 568px) {
  .DashboardScreen > .layoutFlow > .elScheduledItem {
    position: relative;
    margin-top: 36px;
    width: 334px;
    margin-left: 0px;
  }
  .DashboardScreen > .layoutFlow > .elScheduledItem > * { 
  width: 334px;
  }
  
  .DashboardScreen > .layoutFlow > .elScheduledItem > .background > .containerMinHeight {
    min-height: 150px;
  }

}
@media (min-width: 768px) {
  .DashboardScreen > .layoutFlow > .elScheduledItem {
    position: relative;
    margin-top: 36px;
    width: 332px;
    margin-left: 0px;
  }
  .DashboardScreen > .layoutFlow > .elScheduledItem > * { 
  width: 332px;
  }
  
  .DashboardScreen > .layoutFlow > .elScheduledItem > .background > .containerMinHeight {
    min-height: 149px;
  }

}
@media (min-width: 1024px) {
  .DashboardScreen > .layoutFlow > .elScheduledItem {
    position: relative;
    margin-top: 36px;
    width: 332px;
    margin-left: 0px;
  }
  .DashboardScreen > .layoutFlow > .elScheduledItem > * { 
  width: 332px;
  }
  
  .DashboardScreen > .layoutFlow > .elScheduledItem > .background > .containerMinHeight {
    min-height: 149px;
  }

}

.DashboardScreen .elAccountButton {
  overflow: hidden;
}

.DashboardScreen .elAccountButton.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton {
    position: fixed;
    right: 0px;
    top: 0px;
    width: 45px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton > .background > .containerMinHeight {
    min-height: 58px;
  }

@media (min-width: 568px) {
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton {
    right: 0px;
    top: 0px;
    width: 45px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton > .background > .containerMinHeight {
    min-height: 58px;
  }

}
@media (min-width: 768px) {
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton {
    right: 0px;
    top: 0px;
    width: 44px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton > .background > .containerMinHeight {
    min-height: 58px;
  }

}
@media (min-width: 1024px) {
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton {
    right: 0px;
    top: 0px;
    width: 44px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elAccountButton > .background > .containerMinHeight {
    min-height: 58px;
  }

}

.DashboardScreen .elMenuButtonCopy2 {
  overflow: hidden;
}

.DashboardScreen .elMenuButtonCopy2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 {
    position: absolute;
    left: 20px;
    top: 0px;
    width: 45px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 > .background > .containerMinHeight {
    min-height: 58px;
  }

@media (min-width: 568px) {
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 {
    left: 20px;
    top: 0px;
    width: 45px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 > .background > .containerMinHeight {
    min-height: 58px;
  }

}
@media (min-width: 768px) {
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 {
    left: 20px;
    top: 0px;
    width: 44px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 > .background > .containerMinHeight {
    min-height: 58px;
  }

}
@media (min-width: 1024px) {
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 {
    left: 20px;
    top: 0px;
    width: 44px;
    height: 58px;
  }
  .DashboardScreen > .screenFgContainer > .foreground > .elMenuButtonCopy2 > .background > .containerMinHeight {
    min-height: 58px;
  }

}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Screen21 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Screen21 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 238px;
    margin-left: 46px;
  }
  .Screen21 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .Screen21 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 238px;
    margin-left: 46px;
  }
  .Screen21 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .Screen21 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 236px;
    margin-left: 46px;
  }
  .Screen21 > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .Screen21 > .layoutFlow > .elText {
    position: relative;
    margin-top: 12px;
    width: 236px;
    margin-left: 46px;
  }
  .Screen21 > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .screenFgContainer > .foreground > .elDashboard {
    position: absolute;
    left: 59px;
    top: 133px;
    width: 208px;
    height: 370px;
  }
@media (min-width: 568px) {
  .Screen21 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 133px;
    width: 208px;
    height: 370px;
  }
}
@media (min-width: 768px) {
  .Screen21 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 132px;
    width: 207px;
    height: 367px;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .screenFgContainer > .foreground > .elDashboard {
    left: 59px;
    top: 132px;
    width: 207px;
    height: 367px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 8px;
    top: 467px;
    width: 302px;
    height: 28px;
  }
@media (min-width: 568px) {
  .Screen21 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 467px;
    width: 301px;
    height: 28px;
  }
}
@media (min-width: 768px) {
  .Screen21 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 464px;
    width: 300px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .screenFgContainer > .foreground > .elRectangle {
    left: 8px;
    top: 464px;
    width: 300px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    position: absolute;
    left: 18px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .Screen21 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 18px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .Screen21 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 17px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .screenFgContainer > .foreground > .elText2 {
    overflow: hidden;
    left: 17px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .screenFgContainer > .foreground > .elEllipse {
    position: absolute;
    left: calc(50.0% + 27px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipse {
    left: calc(50.0% + 27px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy {
    position: absolute;
    left: calc(50.0% + 0px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy {
    left: calc(50.0% + 0px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    position: absolute;
    left: calc(50.0% + -30px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
@media (min-width: 568px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 472px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 768px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .screenFgContainer > .foreground > .elEllipseCopy2 {
    left: calc(50.0% + -30px - 9px);
    top: 469px;
    width: 18px;
    height: 17px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Screen21 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    position: absolute;
    left: 263px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .Screen21 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 263px;
    top: 471px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .Screen21 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 261px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .Screen21 > .screenFgContainer > .foreground > .elTextCopy {
    overflow: hidden;
    left: 261px;
    top: 468px;
    width: 236px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Login1Screen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Login1Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Login1Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Login1Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Login1Screen > .layoutFlow > .elL1060427 {
    position: relative;
    margin-top: 0px;
    width: 240px;
    margin-left: calc(50.0% - 120px);
  }
  .Login1Screen > .layoutFlow > .elL1060427 > * { 
  width: 240px;
  height: 190px;
  }
  
@media (min-width: 568px) {
  .Login1Screen > .layoutFlow > .elL1060427 {
    position: relative;
    margin-top: 0px;
    width: 240px;
    margin-left: calc(50.0% - 120px);
  }
  .Login1Screen > .layoutFlow > .elL1060427 > * { 
  width: 240px;
  height: 190px;
  }
  
}
@media (min-width: 768px) {
  .Login1Screen > .layoutFlow > .elL1060427 {
    position: relative;
    margin-top: 0px;
    width: 239px;
    margin-left: calc(50.0% - 120px);
  }
  .Login1Screen > .layoutFlow > .elL1060427 > * { 
  width: 239px;
  height: 190px;
  }
  
}
@media (min-width: 1024px) {
  .Login1Screen > .layoutFlow > .elL1060427 {
    position: relative;
    margin-top: 0px;
    width: 239px;
    margin-left: calc(50.0% - 120px);
  }
  .Login1Screen > .layoutFlow > .elL1060427 > * { 
  width: 239px;
  height: 190px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Login1Screen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 40px;
    width: 207px;
    margin-left: calc(50.0% - 104px);
  }
  .Login1Screen > .layoutFlow > .elFirebaseLogin > * { 
  width: 207px;
  height: 48px;
  }
  
@media (min-width: 568px) {
  .Login1Screen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 40px;
    width: 207px;
    margin-left: calc(50.0% - 104px);
  }
  .Login1Screen > .layoutFlow > .elFirebaseLogin > * { 
  width: 207px;
  height: 48px;
  }
  
}
@media (min-width: 768px) {
  .Login1Screen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 40px;
    width: 206px;
    margin-left: calc(50.0% - 103px);
  }
  .Login1Screen > .layoutFlow > .elFirebaseLogin > * { 
  width: 206px;
  height: 47px;
  }
  
}
@media (min-width: 1024px) {
  .Login1Screen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 40px;
    width: 206px;
    margin-left: calc(50.0% - 103px);
  }
  .Login1Screen > .layoutFlow > .elFirebaseLogin > * { 
  width: 206px;
  height: 47px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Login1Screen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 178px;
    width: 207px;
    margin-left: calc(50.0% - 104px);
  }
  .Login1Screen > .layoutFlow > .elText > * { 
  width: 207px;
  }
  
@media (min-width: 568px) {
  .Login1Screen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 178px;
    width: 207px;
    margin-left: calc(50.0% - 104px);
  }
  .Login1Screen > .layoutFlow > .elText > * { 
  width: 207px;
  }
  
}
@media (min-width: 768px) {
  .Login1Screen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 178px;
    width: 206px;
    margin-left: calc(50.0% - 103px);
  }
  .Login1Screen > .layoutFlow > .elText > * { 
  width: 206px;
  }
  
}
@media (min-width: 1024px) {
  .Login1Screen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 178px;
    width: 206px;
    margin-left: calc(50.0% - 103px);
  }
  .Login1Screen > .layoutFlow > .elText > * { 
  width: 206px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Login1Screen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 36px;
    width: 207px;
    margin-left: calc(50.0% - 104px);
  }
  .Login1Screen > .layoutFlow > .elTextCopy > * { 
  width: 207px;
  }
  
@media (min-width: 568px) {
  .Login1Screen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 36px;
    width: 207px;
    margin-left: calc(50.0% - 104px);
  }
  .Login1Screen > .layoutFlow > .elTextCopy > * { 
  width: 207px;
  }
  
}
@media (min-width: 768px) {
  .Login1Screen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 36px;
    width: 206px;
    margin-left: calc(50.0% - 103px);
  }
  .Login1Screen > .layoutFlow > .elTextCopy > * { 
  width: 206px;
  }
  
}
@media (min-width: 1024px) {
  .Login1Screen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 36px;
    width: 206px;
    margin-left: calc(50.0% - 103px);
  }
  .Login1Screen > .layoutFlow > .elTextCopy > * { 
  width: 206px;
  }
  
}

/* This component has a fixed-size layout */
  .JobItemsMockData {
    min-height: 827px;  /* Minimum height to ensure layout flow is visible */
    min-width: 322px;
     /* This component has a fixed size */
  }
  .JobItemsMockData > .background > .containerMinHeight {
    min-height: 827px;
  }


  .JobItemsMockData > .background > .elCard {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 320px;
    height: 472px;
  }


  .JobItemsMockData > .layoutFlow > .elPixel012 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .JobItemsMockData > .layoutFlow > .elPixel012 > * { 
    width: 100%;
  height: 216px;
  }
  


  .JobItemsMockData > .layoutFlow > .elText {
    position: relative;
    margin-top: 8px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItemsMockData > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

.JobItemsMockData .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.JobItemsMockData .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 149, 214, 0.5000);
  outline: none;
}



  .JobItemsMockData > .layoutFlow > .elButton {
    line-height: 22px;
    position: relative;
    margin-top: 14px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .JobItemsMockData > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 22px;
  }
  


  .JobItemsMockData > .layoutFlow > .elCard2 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .JobItemsMockData > .layoutFlow > .elCard2 > * { 
    width: 100%;
  height: 32px;
  }
  

/* This component has a fixed-size layout */
  .DashbboardContent {
    min-height: 433px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 315px;
     /* This component has a fixed size */
  }
  .DashbboardContent > .background > .containerMinHeight {
    min-height: 433px;
  }

/* This component has a fixed-size layout */
  .Component1 {
    min-height: 154px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 334px;
     /* This component has a fixed size */
  }
  .Component1 > .background > .containerMinHeight {
    min-height: 154px;
  }


  .Component1 > .background > .elBackground {
    position: absolute;
    top: 0px;
    width: 334px;
    height: 428px;
  }

